<template>
  <network :workspace-id="workspaceId" />
</template>

<script lang="ts">
import { message } from 'ant-design-vue'
import { computed, defineComponent } from 'vue'
import { NavigationGuard, NavigationGuardWithThis, useRoute } from 'vue-router'

import Network from '@/components/network/Network.vue'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { Network as BayesNetwork } from '@/libs/bayes'
import { useStore } from '@/store'
import {
  AllocationActionEnum,
  NetworkActionEnum,
  QuestionnaireActionEnum,
  SurveyActionEnum,
  WorkspaceActionEnum
} from '@/store/enums/actions'
import { AuthStateEnum, NetworkStateEnum } from '@/store/enums/states'
import { vuexActions } from '@/store/util'
import { NetworkSchema } from '@/types'

import { default as defaultNetwork } from '../models/minimal.json'

const { ALLOCATION, AUTH, NETWORK, QUESTIONNAIRE, SURVEY, WORKSPACE } = ModuleNames

const loadPage: NavigationGuardWithThis<undefined> | NavigationGuard = async (to, from, next) => {
  const store = useStore()
  let { workspaceId } = to.params
  const userId = store.state[AUTH][AuthStateEnum.USER]?.id
  let error

  if (Array.isArray(workspaceId)) {
    workspaceId = workspaceId[0]
  }
  if (!workspaceId) {
    error = `Unexpected workspaceId ${workspaceId}. You will be redirect to home page in 3 seconds.`
  } else if (!userId) {
    error = `Unexpected userId ${userId}. You will be redirect to home page in 3 seconds.`
  }

  if (error) {
    message.error({
      content: error,
      duration: 3
    })
    setTimeout(() => {
      next(PATH.HOME)
    }, 3000)
  } else {
    await store.dispatch(vuexActions(WORKSPACE, WorkspaceActionEnum.GET_WORKSPACE), workspaceId)
    await store.dispatch(vuexActions(NETWORK, NetworkActionEnum.GET_NETWORKS), workspaceId)
    // Get allocations for current workspace if exist
    await store.dispatch(vuexActions(ALLOCATION, AllocationActionEnum.GET_ALLOCATIONS), workspaceId)
    // Get surveys for current workspace if exist
    await store.dispatch(vuexActions(SURVEY, SurveyActionEnum.GET_SURVEYS), workspaceId)
    let currentNetwork = store.state[NETWORK][NetworkStateEnum.CURRENT_NETWORK]
    // Get CPTs for current network in server if exist
    let currentNetworkId = currentNetwork?.id
    if (!currentNetworkId) {
      const network = new BayesNetwork(defaultNetwork as NetworkSchema)
      network.nodes = []
      network.nodeMap = {}
      network.edges = []
      network.edgeMap = {}
      await store.dispatch(vuexActions(NETWORK, NetworkActionEnum.CREATE_NETWORK), {
        workspaceId,
        network: network.serialize(workspaceId, true)
      })
      await store.dispatch(vuexActions(NETWORK, NetworkActionEnum.GET_NETWORKS), workspaceId)
      currentNetwork = store.state[NETWORK][NetworkStateEnum.CURRENT_NETWORK]
      currentNetworkId = currentNetwork?.id
    }
    await store.dispatch(
      vuexActions(QUESTIONNAIRE, QuestionnaireActionEnum.GET_NETWORK_CPTS),
      currentNetworkId
    )
    next()
  }
}

export default defineComponent({
  components: {
    Network
  },
  beforeRouteEnter: loadPage as NavigationGuard,
  beforeRouteUpdate: loadPage as NavigationGuardWithThis<undefined>,
  setup() {
    const route = useRoute()
    const workspaceId = computed(() => {
      if (typeof route.params.workspaceId === 'string') {
        return route.params.workspaceId
      }
      return null
    })
    return {
      workspaceId
    }
  }
})
</script>
