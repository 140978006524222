import { render } from "./GenieFileImporter.vue?vue&type=template&id=cf8e35ce"
import script from "./GenieFileImporter.vue?vue&type=script&lang=ts"
export * from "./GenieFileImporter.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "cf8e35ce"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('cf8e35ce', script)) {
    api.reload('cf8e35ce', script)
  }
  
  module.hot.accept("./GenieFileImporter.vue?vue&type=template&id=cf8e35ce", () => {
    api.rerender('cf8e35ce', render)
  })

}

script.__file = "src/components/network/GenieFileImporter.vue"

export default script