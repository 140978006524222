<template>
  <a-modal v-model:visible="isVisible" title="Upload network" @cancel="handleCancel">
    <template v-if="Array.from(existedRecordText).length > 0" #footer>
      <a-button key="back" @click="handleCancel">Cancel</a-button>
      <a-button key="submit" type="primary" :loading="loadingStatus" @click="handleConfirm"
        >Delete</a-button
      >
    </template>
    <template v-else #footer>
      <a-button key="back" @click="handleCancel">Cancel</a-button>
    </template>
    <div v-if="Array.from(existedRecordText).length > 0">
      <p>
        We have
        <strong>{{
          Array.from(existedRecordText)
            .map((each) => each)
            .join(', ')
        }}</strong>
        for previous network.
      </p>
      <p>Please confirm your deletion before continuing</p>
      <p>
        <a-checkbox
          v-if="Array.from(existedRecordText).includes(NETWORK_EXISTED_RECORD_TEXT.NETWORK)"
          :checked="true"
          :disabled="true"
        >
          Network</a-checkbox
        >
        <a-checkbox
          v-if="Array.from(existedRecordText).includes(NETWORK_EXISTED_RECORD_TEXT.ALLOCATION)"
          v-model:checked="recordDeletionCheckBox.isDeletingAllocation"
          :disabled="true"
        >
          Allocation</a-checkbox
        >
        <a-checkbox
          v-if="Array.from(existedRecordText).includes(NETWORK_EXISTED_RECORD_TEXT.CPT)"
          v-model:checked="recordDeletionCheckBox.isDeletingCPT"
          :disabled="true"
        >
          CPT</a-checkbox
        >
        <a-checkbox
          v-if="Array.from(existedRecordText).includes(NETWORK_EXISTED_RECORD_TEXT.SURVEY)"
          v-model:checked="recordDeletionCheckBox.isDeletingSurvey"
          :disabled="true"
        >
          Survey</a-checkbox
        >
        <a-checkbox
          v-if="Array.from(existedRecordText).includes(NETWORK_EXISTED_RECORD_TEXT.SURVEY)"
          v-model:checked="recordDeletionCheckBox.isDeletingSurveyResponse"
          :disabled="true"
        >
          Survey response</a-checkbox
        >
      </p>
      <p v-if="deletionPromptText !== ''" style="text-align: center">
        {{ deletionPromptText }}
      </p>
      <i
        >Note: disabled checkbox is specific for each network so it must be deleted when updating
        network
      </i>
    </div>
    <div v-else>
      <p>
        You don't have previous related data yet, please upload XDSL file to upload a new network
      </p>
      <Import
        v-if="workspaceId"
        class="sz-import"
        :workspace-id="workspaceId"
        v-on="{
          [EMIT_EVENTS.NETWORK.LOAD]: handleLoadNetwork
        }"
      />
    </div>
  </a-modal>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, UnwrapRef } from 'vue'

import Import from '@/components/network/GenieFileImporter.vue'
import { NETWORK_EXISTED_RECORD_TEXT } from '@/constants/components'
import { EMIT_EVENTS } from '@/constants/emits'
import { NetworkSchema } from '@/types'
import { NetworkDataDeletionCheckboxJSON } from '@/types/component'

export default defineComponent({
  components: { Import },
  props: {
    existedRecordText: Set,
    isNetworkUploadModalVisible: { type: Boolean, required: true },
    loadingStatus: { type: Boolean, default: false },
    deletionPromptText: { type: String, default: undefined },
    workspaceId: { type: String, default: undefined }
  },
  emits: [EMIT_EVENTS.NETWORK.LOAD],
  setup(props, { emit }) {
    const isVisible = computed(() => props.isNetworkUploadModalVisible)
    const recordDeletionCheckBox: UnwrapRef<NetworkDataDeletionCheckboxJSON> = reactive({
      isDeletingSurvey: true,
      isDeletingSurveyResponse: true,
      isDeletingCPT: true,
      isDeletingAllocation: true
    })

    /**
     * Handle load network
     **/
    const handleLoadNetwork = (network: NetworkSchema) => {
      emit(EMIT_EVENTS.NETWORK.LOAD, network)
    }

    /**
     * Handle click cancel
     */
    const handleCancel = () => {
      emit(EMIT_EVENTS.NETWORK.TOGGLE_IMPORT_NETWORK_MODAL)
    }

    /**
     * Handle confirm
     */
    const handleConfirm = () => {
      emit(EMIT_EVENTS.NETWORK.CONFIRM_DELETE_NETWORK_RECORDS, recordDeletionCheckBox)
    }

    return {
      NETWORK_EXISTED_RECORD_TEXT,
      EMIT_EVENTS,
      isVisible,
      recordDeletionCheckBox,
      handleCancel,
      handleConfirm,
      handleLoadNetwork
    }
  }
})
</script>
