<template>
  <div class="sz-network sz-wrapper">
    <div class="sz-network-cmds">
      <RoundedMenu
        size="36px"
        tooltip="Import XDSL"
        class="sz-import"
        @click="toggleImportNetworkModal"
      >
        <template #icon>
          <InboxOutlined />
        </template>
      </RoundedMenu>
      <RoundedMenu
        v-if="!currentNetwork"
        size="36px"
        tooltip="Save current network"
        @click="toggleDefaultNetworkSaveModal"
      >
        <template #icon>
          <DeliveredProcedureOutlined />
        </template>
      </RoundedMenu>
      <RoundedMenu
        v-if="currentNetwork"
        size="36px"
        tooltip="Save network settings"
        @click="toggleVariableChangesSaveModal"
      >
        <template #icon>
          <DeliveredProcedureOutlined />
        </template>
      </RoundedMenu>
    </div>
    <splitpanes class="default-theme">
      <pane min-size="10" size="50">
        <div class="sz-variables-container">
          <VariableTable
            v-if="network && network.variables && selectedVariable"
            :selected-variable="selectedVariable"
            :network="network"
            :config="{
              actionVisible: true,
              allocationVisible: false,
              dependencyVisible: true,
              indexVisible: true,
              statesVisible: true,
              variableNameVisible: true
            }"
            v-on="{
              [EMIT_EVENTS.VARIABLE.CHANGE]: handleVariableChanged,
              [EMIT_EVENTS.VARIABLE.SELECT]: selectVariable
            }"
          />
        </div>
      </pane>
      <pane>
        <!-- <div class="sz-graph-container" :style="maxStyle"> -->
        <div :style="maxStyle">
          <div :style="chartStyle">
            <Graph
              id="cy"
              ref="graph"
              :key="graphComponentKey"
              :elements="elements"
              @clickNode="selectVariable"
            />
          </div>
          <div class="control">
            <a-button type="link" size="small" @click="toggle">
              <template #icon>
                <Icon :size="16" color="black">
                  <Minimize v-if="isMaximize" />
                  <Maximize v-else />
                </Icon>
              </template>
            </a-button>
            <a-button type="link" size="small" @click="exportImage">
              <template #icon>
                <Icon :size="16" color="black">
                  <Photo />
                </Icon>
              </template>
            </a-button>
          </div>
        </div>
      </pane>
    </splitpanes>
    <!--    save default network modal-->
    <DefaultNetworkSaveModal
      v-if="!currentNetwork"
      :is-default-network-save-modal-visible="isDefaultNetworkSaveModalVisible"
      v-on="{
        [EMIT_EVENTS.NETWORK.TOGGLE_DEFAULT_NETWORK_SAVE_MODAL]: toggleDefaultNetworkSaveModal,
        [EMIT_EVENTS.NETWORK.SAVE_DEFAULT_NETWORK]: handleSaveNetwork
      }"
    />
    <!--    Save variable change modal-->
    <VariableSettingSaveModal
      v-if="currentNetwork"
      :is-variable-changes-save-modal-visible="isVariableChangesSaveModalVisible"
      :existed-record-text="existedRecordText"
      :loading-status="confirmationLoading"
      :deletion-prompt-text="deletingPrompText"
      v-on="{
        [EMIT_EVENTS.NETWORK.TOGGLE_VARIABLE_CHANGES_SAVE_MODAL]: toggleVariableChangesSaveModal,
        [EMIT_EVENTS.NETWORK.SAVE_VARIABLE_CHANGES]: handleSaveNetwork
      }"
    />
    <!--    upload network modal-->
    <ImportNetworkModal
      :loading-status="confirmationLoading"
      :existed-record-text="existedRecordText"
      :is-network-upload-modal-visible="isNetworkUploadModalVisible"
      :deletion-prompt-text="deletingPrompText"
      :workspace-id="workspaceId"
      v-on="{
        [EMIT_EVENTS.NETWORK.TOGGLE_IMPORT_NETWORK_MODAL]: toggleImportNetworkModal,
        [EMIT_EVENTS.NETWORK.CONFIRM_DELETE_NETWORK_RECORDS]: handleDeleteNetworkRecord,
        [EMIT_EVENTS.NETWORK.LOAD]: onLoad
      }"
    />
  </div>
</template>

<script lang="ts">
import 'splitpanes/dist/splitpanes.css'

import { DeliveredProcedureOutlined, InboxOutlined } from '@ant-design/icons-vue'
import { Maximize, Minimize, Photo } from '@vicons/tabler'
import { Icon } from '@vicons/utils'
import { message, Modal } from 'ant-design-vue'
import { isEmpty } from 'lodash-es'
import { Pane, Splitpanes } from 'splitpanes'
import { computed, defineComponent, provide, ref, watch } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

import useMaximizer from '@/components/composables/maximizer'
import DefaultNetworkSaveModal from '@/components/network/DefaultNetworkSaveModal.vue'
import Graph from '@/components/network/Graph.vue'
import ImportNetworkModal from '@/components/network/ImportNetworkModal.vue'
import VariableSettingSaveModal from '@/components/network/VariableSettingSaveModal.vue'
import VariableTable from '@/components/variable/VariableTable.vue'
import RoundedMenu from '@/components/widgets/RoundedMenu.vue'
import { NETWORK_EXISTED_RECORD_TEXT } from '@/constants/components'
import { DB_FIELDS } from '@/constants/database'
import { EMIT_EVENTS } from '@/constants/emits'
import { ModuleNames } from '@/constants/vuex'
import { Network, Variable } from '@/libs/bayes'
import { Provider } from '@/libs/constant'
import { deleteCPT } from '@/services/api/cpt'
// import { deleteResponse, getSurveyResponses } from '@/services/api/response'
import { useStore } from '@/store'
import { NetworkActionEnum, SurveyActionEnum } from '@/store/enums/actions'
import { AllocationGetterEnum, SurveyGetterEnum } from '@/store/enums/getters'
import { NetworkStateEnum, QuestionnaireStateEnum } from '@/store/enums/states'
import { vuexActions, vuexGetters } from '@/store/util'
import { CPTSchema, NetworkSchema, SurveySchema } from '@/types'
import { NetworkDataDeletionCheckboxJSON } from '@/types/component'
import { WorkspaceId } from '@/types/vue'

import { default as sampleNetwork } from '../../models/minimal.json'

export default defineComponent({
  components: {
    Icon,
    Maximize,
    Minimize,
    Pane,
    Photo,
    Splitpanes,
    VariableTable,
    DefaultNetworkSaveModal,
    VariableSettingSaveModal,
    ImportNetworkModal,
    Graph,
    RoundedMenu,
    // MenuItem: Menu.Item,
    // Menu,
    DeliveredProcedureOutlined,
    InboxOutlined
  },
  props: {
    workspaceId: {
      type: String as WorkspaceId,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const graph = ref()
    const isDirty = ref<boolean>(false)

    // Modal
    const isDefaultNetworkSaveModalVisible = ref<boolean>(false)
    const isVariableChangesSaveModalVisible = ref<boolean>(false)
    const isNetworkUploadModalVisible = ref<boolean>(false)
    const confirmationLoading = ref<boolean>(false)

    const currentNetwork = computed(
      () => store.state[ModuleNames.NETWORK][NetworkStateEnum.CURRENT_NETWORK]
    )
    const networksForWorkspace = computed(
      () => store.state[ModuleNames.NETWORK][NetworkStateEnum.NETWORK_LIST]
    )
    const allocationsForWorkspace = computed(
      () => store.getters[vuexGetters(ModuleNames.ALLOCATION, AllocationGetterEnum.ALLOCATIONS)]
    )
    const surveysForExistedNetwork = computed(
      () => store.getters[vuexGetters(ModuleNames.SURVEY, SurveyGetterEnum.SURVEYS)]
    )
    const cptsForCurrentNetwork = computed(
      () => store.state[ModuleNames.QUESTIONNAIRE][QuestionnaireStateEnum.NETWORK_CPTS]?.content
    )
    const existedRecordText = ref<Set<any>>(new Set())
    const deletingPrompText = ref<string>('')

    const graphComponentKey = ref(0) // Object props change not trigger re-render
    const getCurrentNetwork = (): NetworkSchema => {
      if (!currentNetwork.value) {
        return sampleNetwork as NetworkSchema
      }
      if (!currentNetwork.value.nodes?.length) {
        message.error({
          content:
            'Network is corrupted and reverted to a sample network. Please reupload the network',
          duration: 3
        })
        return sampleNetwork as NetworkSchema
      }
      return currentNetwork.value
    }

    let net = new Network(getCurrentNetwork())
    const network = ref(net)
    const elements = ref(net.cyElements)
    const variables = ref(net.variables)
    const variableMap = ref(net.variableMap)
    let defaultID = net.variables?.[0].id
    const selectedVariable = ref(net.variables?.[0])
    const selectedVariableID = ref(defaultID)
    const parents = ref(net.parents[defaultID] || [])
    const children = ref(net.children[defaultID] || [])

    provide(Provider.NETWORK_SOURCE, currentNetwork)
    provide(Provider.NETWORK, network)
    provide(Provider.VARIABLES, variables)
    provide(Provider.VARIABLE_MAP, variableMap)
    provide(Provider.SELECTED_VARIABLE, selectedVariable)
    provide(Provider.PARENTS, parents)
    provide(Provider.CHILDREN, children)

    const handleVariableChanged = () => {
      isDirty.value = true
    }

    /**
     * Handle load xdsl file
     * Only trigger when import a new network
     **/
    const onLoad = async (source: any) => {
      net = new Network(source)
      network.value = net
      elements.value = Object.assign({}, net.cyElements)
      graphComponentKey.value += 1
      variables.value = net.variables
      variableMap.value = net.variableMap
      defaultID = net.variables[0].id
      selectedVariable.value = net.variables[0]
      selectedVariableID.value = defaultID
      parents.value = net.parents[defaultID] || []
      children.value = net.children[defaultID] || []
      isDirty.value = true
      isNetworkUploadModalVisible.value = false
    }

    /**
     * Delete CPTs for current network
     **/
    const deleteCPTs = async () => {
      if (!isEmpty(cptsForCurrentNetwork.value) && Array.isArray(cptsForCurrentNetwork.value)) {
        const totalLength = cptsForCurrentNetwork.value?.length
        for (let i = 0; i < totalLength; i++) {
          const currentCPT = cptsForCurrentNetwork.value[i] as CPTSchema
          if (currentCPT.id && currentCPT.networkId === currentNetwork.value?.id) {
            deletingPrompText.value = `Deleting ${i + 1} / ${totalLength} CPT(s)`
            await deleteCPT(currentCPT.id)
          }
        }
      }
    }

    /**
     * Delete survey response
     **/
    /*const deleteSurveyResponse = async () => {
      if (
        !isEmpty(surveysForExistedNetwork.value) &&
        Array.isArray(surveysForExistedNetwork.value)
      ) {
        for (let i = 0; i < surveysForExistedNetwork.value?.length; i++) {
          const currentSurvey = surveysForExistedNetwork.value[i] as SurveySchema
          if (currentSurvey.id && props.workspaceId === currentSurvey.workspaceId) {
            const responsesRes = await getSurveyResponses({
              surveyId: currentSurvey.id
            })
            const response = responsesRes?.content
            const totalResponseLength = response?.length
            for (let j = 0; j < totalResponseLength; j++) {
              const currentResponse = totalResponseLength[j]
              const id = currentResponse?.id
              if (id) {
                deletingPrompText.value = `Deleting ${
                  i + 1
                } / ${totalResponseLength} survey response(s) for survey ${i + 1}`
                await deleteResponse(id)
              }
            }
          }
        }
      }
    }*/

    /**
     * Delete surveys in current network
     **/
    const deleteSurvey = async () => {
      if (
        !isEmpty(surveysForExistedNetwork.value) &&
        Array.isArray(surveysForExistedNetwork.value)
      ) {
        const totalLength = surveysForExistedNetwork.value?.length
        for (let i = 0; i < totalLength; i++) {
          const currentSurvey = surveysForExistedNetwork.value[i] as SurveySchema
          if (currentSurvey.id && props.workspaceId === currentSurvey.workspaceId) {
            deletingPrompText.value = `Deleting ${i + 1} / ${totalLength} Survey(s)`
            await store.dispatch(
              vuexActions(ModuleNames.SURVEY, SurveyActionEnum.DELETE_SURVEY),
              currentSurvey.id
            )
          }
        }
      }
    }

    /**
     * Toggle display save default network modal
     **/
    const toggleDefaultNetworkSaveModal = () => {
      isDefaultNetworkSaveModalVisible.value = !isDefaultNetworkSaveModalVisible.value
    }

    /**
     * Toggle display save variable setting changes modal
     **/
    const toggleVariableChangesSaveModal = () => {
      isVariableChangesSaveModalVisible.value = !isVariableChangesSaveModalVisible.value
    }

    /**
     * Toggle display save variable setting changes modal
     **/
    const toggleImportNetworkModal = () => {
      isNetworkUploadModalVisible.value = !isNetworkUploadModalVisible.value
    }

    /**
     * Handle save variable setting changes
     **/
    const handleSaveNetwork = async (checkboxStatus: Partial<NetworkDataDeletionCheckboxJSON>) => {
      const workspaceId = props.workspaceId
      if (workspaceId) {
        isDirty.value = false
        confirmationLoading.value = false
        if (isEmpty(currentNetwork.value)) {
          // If this workspace doesn't have network
          let currentNetworkValue = net.serialize(workspaceId)
          // If we don't have network available, then save means create a new one
          await store.dispatch(vuexActions(ModuleNames.NETWORK, NetworkActionEnum.CREATE_NETWORK), {
            workspaceId,
            network: currentNetworkValue
          })
          toggleDefaultNetworkSaveModal()
        } else {
          // If this workspace already has network
          // Get latest changes for current network
          const networkId = currentNetwork.value?.id
          if (networkId) {
            const currentNetworkValue = net.serialize(workspaceId, false)
            // If we want to update settings then just update current network
            store.dispatch(vuexActions(ModuleNames.NETWORK, NetworkActionEnum.UPDATE_NETWORK), {
              id: networkId,
              network: currentNetworkValue
            })
            // if (checkboxStatus.isDeletingSurveyResponse) {
            //   await deleteSurveyResponse()
            // }
            if (checkboxStatus.isDeletingCPT) {
              await deleteCPTs()
            }
            if (checkboxStatus.isDeletingSurvey) {
              await deleteSurvey()
            }
          }
          existedRecordText.value = new Set()
          toggleVariableChangesSaveModal()
        }
        setTimeout(() => {
          confirmationLoading.value = false
        }, 2000)
      }
    }

    /**
     * Handle save current network
     **/
    const handleDeleteNetworkRecord = async (
      checkboxStatus: Partial<NetworkDataDeletionCheckboxJSON>
    ) => {
      const workspaceId = props.workspaceId
      if (workspaceId) {
        confirmationLoading.value = true
        // If this workspace already has network
        if (!isEmpty(currentNetwork.value)) {
          // Get latest changes for current network
          const networkId = currentNetwork.value?.id
          if (networkId) {
            // if (checkboxStatus.isDeletingSurveyResponse) {
            //   await deleteSurveyResponse()
            // }
            if (checkboxStatus.isDeletingSurvey) {
              await deleteSurvey()
            }
            if (networksForWorkspace.value) {
              const promises: Promise<any>[] = []
              networksForWorkspace.value.content.forEach((network: NetworkSchema) => {
                const id = network[DB_FIELDS.NETWORK.ID]
                promises.push(
                  store.dispatch(
                    vuexActions(ModuleNames.NETWORK, NetworkActionEnum.DELETE_NETWORK),
                    id
                  ) as Promise<any>
                )
              })
              await Promise.all(promises)
            }
          }
        }
        setTimeout(() => {
          existedRecordText.value = new Set()
          confirmationLoading.value = false
        }, 2000)
      }
    }

    const checkReferentialDeps = () => {
      if (!isEmpty(networksForWorkspace)) {
        existedRecordText.value.add(NETWORK_EXISTED_RECORD_TEXT.NETWORK)
      } else {
        existedRecordText.value.delete(NETWORK_EXISTED_RECORD_TEXT.NETWORK)
      }
      if (!isEmpty(allocationsForWorkspace.value)) {
        existedRecordText.value.add(NETWORK_EXISTED_RECORD_TEXT.ALLOCATION)
      } else {
        existedRecordText.value.delete(NETWORK_EXISTED_RECORD_TEXT.ALLOCATION)
      }
      if (!isEmpty(surveysForExistedNetwork.value)) {
        existedRecordText.value.add(NETWORK_EXISTED_RECORD_TEXT.SURVEY)
      } else {
        existedRecordText.value.delete(NETWORK_EXISTED_RECORD_TEXT.SURVEY)
      }
      if (!isEmpty(cptsForCurrentNetwork.value)) {
        existedRecordText.value.add(NETWORK_EXISTED_RECORD_TEXT.CPT)
      } else {
        existedRecordText.value.delete(NETWORK_EXISTED_RECORD_TEXT.CPT)
      }
    }

    /**
     * Display prompt text when save or update network
     * Used to let users know which data will be deleted
     **/
    watch(
      [
        currentNetwork,
        networksForWorkspace,
        allocationsForWorkspace,
        surveysForExistedNetwork,
        cptsForCurrentNetwork
      ],
      () => {
        checkReferentialDeps()
      }
    )

    watch(currentNetwork, () => {
      if (currentNetwork.value) {
        net = new Network(currentNetwork.value)
        network.value = net
        elements.value = net.cyElements
        variables.value = net.variables
        variableMap.value = net.variableMap
        parents.value = net.parents[selectedVariableID.value] || []
        children.value = net.children[selectedVariableID.value] || []
        isDirty.value = false
      }
    })

    onBeforeRouteLeave((to, from, next) => {
      if (isDirty.value) {
        Modal.confirm({
          title: 'Unsaved Changes',
          content: 'You have unsaved changes. Would you like to save or discard them?',
          okText: 'Save',
          cancelText: 'Discard',
          onOk() {
            if (currentNetwork.value) {
              toggleVariableChangesSaveModal()
            } else {
              toggleDefaultNetworkSaveModal()
            }
          },
          onCancel() {
            next()
          }
        })
      } else {
        next()
      }
    })

    const selectVariable = (variable: Variable) => {
      selectedVariable.value = variable
      selectedVariableID.value = variable.id
      parents.value = net.parents[variable.id] || []
      children.value = net.children[variable.id] || []
    }

    const { isMaximize, toggle, chartStyle, maxStyle } = useMaximizer()

    const exportImage = () => {
      if (graph.value) {
        graph.value?.exportImage()
      }
    }

    return {
      graph,
      exportImage,
      isMaximize,
      toggle,
      chartStyle,
      maxStyle,
      confirmationLoading,
      currentNetwork,
      deletingPrompText,
      elements,
      EMIT_EVENTS,
      existedRecordText,
      graphComponentKey,
      handleDeleteNetworkRecord,
      handleSaveNetwork,
      handleVariableChanged,
      isDefaultNetworkSaveModalVisible,
      isDirty,
      isNetworkUploadModalVisible,
      isVariableChangesSaveModalVisible,
      NETWORK_EXISTED_RECORD_TEXT,
      network,
      networksForWorkspace,
      onLoad,
      selectedVariable,
      selectedVariableID,
      selectVariable,
      toggleDefaultNetworkSaveModal,
      toggleImportNetworkModal,
      toggleVariableChangesSaveModal,
      variables,
      surveysForExistedNetwork,
      cptsForCurrentNetwork
    }
  }
})
</script>

<style lang="stylus">
@import '../styles/Page.styl';

.sz-network
  display flex
  width calc(100% - 48px)
  align-items stretch
  padding 50px 0 0

  .control
    position: absolute;
    right: 8px;
    top: 8px;

  .sz-graph-container
    flex-grow 1
    position absolute
    top 0
    right 0
    left 0
    bottom 0

  .sz-variables-container
    z-index 1000
    width auto
    position absolute
    top 0
    right 0
    left 0
    bottom 0

  .sz-network-cmds
    display: flex
    flex-direction row
    position: absolute
    left 10px
    top: 0px
    > div
      margin 0 12px
    .sz-import
      width: 36px
      height: 36px
</style>
