<template>
  <a-modal title="Save network" :visible="isVisible" @cancel="handleClickCancel">
    <template #footer>
      <a-button key="back" @click="handleClickCancel">Cancel</a-button>
      <a-button key="submit" type="primary" @click="handleClickConfirm">Confirm</a-button>
    </template>
    <div>
      <p>
        You are using the default network, please confirm that you want to assign it to this
        workspace
      </p>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import { EMIT_EVENTS } from '@/constants/emits'

export default defineComponent({
  props: {
    isDefaultNetworkSaveModalVisible: { type: Boolean, required: true }
  },
  emits: [
    EMIT_EVENTS.NETWORK.TOGGLE_DEFAULT_NETWORK_SAVE_MODAL,
    EMIT_EVENTS.NETWORK.SAVE_DEFAULT_NETWORK
  ],
  setup(props, { emit }) {
    const isVisible = computed(() => props.isDefaultNetworkSaveModalVisible)

    /**
     * Handle click cancel button
     */
    const handleClickCancel = () => {
      emit(EMIT_EVENTS.NETWORK.TOGGLE_DEFAULT_NETWORK_SAVE_MODAL)
    }

    /**
     * Handle click confirm button
     */
    const handleClickConfirm = () => {
      emit(EMIT_EVENTS.NETWORK.SAVE_DEFAULT_NETWORK)
    }

    return {
      isVisible,
      handleClickCancel,
      handleClickConfirm
    }
  }
})
</script>
