<template>
  <a-upload-dragger
    v-model:fileList="fileList"
    name="file"
    :multiple="true"
    :show-upload-list="false"
    :custom-request="handleRequest"
    @change="handleChange"
  >
    <p class="ant-upload-drag-icon" title="Click or drag a XDSL file here">
      <inbox-outlined></inbox-outlined>
    </p>
    <p class="ant-upload-hint">click or drag a XDSL file here</p>
  </a-upload-dragger>
</template>

<script lang="ts">
import { InboxOutlined } from '@ant-design/icons-vue'
import { defineComponent, ref } from 'vue'

import { EMIT_EVENTS } from '@/constants/emits'
import { ModuleNames } from '@/constants/vuex'
import { parse } from '@/libs/exim'
import { readAsText } from '@/services/api/utils'
import { useStore } from '@/store'
import { NetworkActionEnum } from '@/store/enums/actions/network'
import { vuexActions } from '@/store/util'

interface FileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  originFileObj: any
}

interface FileInfo {
  file: FileItem
  fileList: FileItem[]
}

/*
function readAsText(img: Blob, callback: (text: string) => void) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsText(img)
}
*/

const IMPORT_METHOD = 'api' // or 'js'

export default defineComponent({
  components: {
    InboxOutlined
  },
  props: {
    workspaceId: { type: String, required: true }
  },
  emits: [EMIT_EVENTS.NETWORK.LOAD],
  setup(props, { emit }) {
    const store = useStore()

    let handleRequest
    let handleChange

    if (IMPORT_METHOD === 'api') {
      handleRequest = async (options: any) => {
        try {
          if (props.workspaceId) {
            const importNetwork = store.dispatch(
              vuexActions(ModuleNames.NETWORK, NetworkActionEnum.IMPORT_NETWORK),
              {
                workspaceId: props.workspaceId,
                file: options.file as File
              }
            )
            console.log('importNetwork', importNetwork)
            let networkRes = await importNetwork
            console.log('networkRes', networkRes)
            if (networkRes) {
              options.onSuccess(networkRes, options.file)
              emit(EMIT_EVENTS.NETWORK.LOAD, networkRes)
            }
          }
        } catch (e) {
          console.log(e)
        }
      }
      handleChange = () => {
        return
      }
    } else {
      // client-side import
      handleRequest = async (options: any) => {
        options.onSuccess({}, { ...options.file })
      }
      handleChange = (info: FileInfo) => {
        const status = info.file.status
        if (status === 'error') {
          console.log(`${info.file.name} file upload failed.`)
        } else {
          // if (status === 'done')
          readAsText(info.file.originFileObj, async (text: string) => {
            const network = await parse(text)
            emit(EMIT_EVENTS.NETWORK.LOAD, network)
          })
        }
      }
    }
    return {
      handleRequest,
      handleChange,
      fileList: ref([])
    }
  }
})
</script>
