import { render } from "./Graph.vue?vue&type=template&id=31100bad"
import script from "./Graph.vue?vue&type=script&lang=js"
export * from "./Graph.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "31100bad"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('31100bad', script)) {
    api.reload('31100bad', script)
  }
  
  module.hot.accept("./Graph.vue?vue&type=template&id=31100bad", () => {
    api.rerender('31100bad', render)
  })

}

script.__file = "src/components/network/Graph.vue"

export default script