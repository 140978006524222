import { render } from "./VariableSettingSaveModal.vue?vue&type=template&id=8c37d866"
import script from "./VariableSettingSaveModal.vue?vue&type=script&lang=ts"
export * from "./VariableSettingSaveModal.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "8c37d866"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8c37d866', script)) {
    api.reload('8c37d866', script)
  }
  
  module.hot.accept("./VariableSettingSaveModal.vue?vue&type=template&id=8c37d866", () => {
    api.rerender('8c37d866', render)
  })

}

script.__file = "src/components/network/VariableSettingSaveModal.vue"

export default script