
import { isNil } from 'ramda'
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    modelValue: { type: Boolean, default: undefined },
    tooltip: { type: String, default: undefined },
    size: { type: String, default: undefined }
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const noEmit = isNil(props.modelValue)
    const state = ref(props.modelValue || false)
    const klass = computed(() => ({
      on: props.modelValue
    }))
    const style = computed(() => ({
      width: props.size,
      height: props.size
    }))
    const toggle = () => {
      if (!noEmit) {
        state.value = !state.value
        context.emit('update:modelValue', state.value)
      }
    }
    return {
      state,
      klass,
      style,
      toggle
    }
  }
})
