<template>
  <a-modal :visible="isVisible" title="Save network variable setting" @cancel="handleCancel">
    <template #footer>
      <a-button key="back" @click="handleCancel">Cancel</a-button>
      <a-button key="submit" type="primary" :loading="loadingStatus" @click="handleConfirm"
        >Confirm</a-button
      >
    </template>
    <div>
      <p>
        Please confirm that you want to save current changes of variables, like dependency and
        re-ordering states
      </p>
    </div>
    <div
      v-if="
        Array.from(existedRecordText).includes(NETWORK_EXISTED_RECORD_TEXT.CPT) ||
        Array.from(existedRecordText).includes(NETWORK_EXISTED_RECORD_TEXT.SURVEY)
      "
    >
      <p>
        If you want to update settings for current network, the associated data below will be
        deleted
      </p>
      <div>
        <a-checkbox
          v-if="Array.from(existedRecordText).includes(NETWORK_EXISTED_RECORD_TEXT.CPT)"
          v-model:checked="recordDeletionCheckBox.isDeletingCPT"
          :disabled="true"
        >
          CPT</a-checkbox
        >
        <a-checkbox
          v-if="Array.from(existedRecordText).includes(NETWORK_EXISTED_RECORD_TEXT.SURVEY)"
          v-model:checked="recordDeletionCheckBox.isDeletingSurvey"
          :disabled="true"
        >
          Survey</a-checkbox
        >
        <a-checkbox
          v-if="Array.from(existedRecordText).includes(NETWORK_EXISTED_RECORD_TEXT.SURVEY)"
          v-model:checked="recordDeletionCheckBox.isDeletingSurveyResponse"
          :disabled="true"
        >
          Survey response</a-checkbox
        >
      </div>
      <p v-if="deletionPromptText !== ''" style="text-align: center">
        {{ deletionPromptText }}
      </p>
      <i
        >Note: disabled checkbox is specific for each network so it must be deleted when updating
        network
      </i>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, UnwrapRef } from 'vue'

import { NETWORK_EXISTED_RECORD_TEXT } from '@/constants/components'
import { EMIT_EVENTS } from '@/constants/emits'
import { NetworkDataDeletionCheckboxJSON } from '@/types/component'

export default defineComponent({
  props: {
    isVariableChangesSaveModalVisible: { type: Boolean, required: true },
    existedRecordText: { type: Set, default: undefined },
    deletionPromptText: { type: String, default: undefined },
    loadingStatus: { type: Boolean, default: false }
  },
  emits: [
    EMIT_EVENTS.NETWORK.TOGGLE_VARIABLE_CHANGES_SAVE_MODAL,
    EMIT_EVENTS.NETWORK.SAVE_VARIABLE_CHANGES
  ],
  setup(props, { emit }) {
    const isVisible = computed(() => props.isVariableChangesSaveModalVisible)
    const recordDeletionCheckBox: UnwrapRef<Partial<NetworkDataDeletionCheckboxJSON>> = reactive({
      isDeletingSurvey: true,
      isDeletingSurveyResponse: true,
      isDeletingCPT: true
    })

    /**
     * Handle cancel
     */
    const handleCancel = () => {
      emit(EMIT_EVENTS.NETWORK.TOGGLE_VARIABLE_CHANGES_SAVE_MODAL)
    }

    /**
     * Handle cancel
     */
    const handleConfirm = () => {
      emit(EMIT_EVENTS.NETWORK.SAVE_VARIABLE_CHANGES, recordDeletionCheckBox)
    }

    return {
      NETWORK_EXISTED_RECORD_TEXT,
      isVisible,
      recordDeletionCheckBox,
      handleCancel,
      handleConfirm
    }
  }
})
</script>
