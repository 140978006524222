<template>
  <div class="sz-rounded-menu" :class="klass" :style="style" @click="toggle">
    <a-tooltip>
      <template #title>{{ tooltip }} </template>
      <slot name="icon"></slot>
    </a-tooltip>
  </div>
</template>

<script lang="ts">
import { isNil } from 'ramda'
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    modelValue: { type: Boolean, default: undefined },
    tooltip: { type: String, default: undefined },
    size: { type: String, default: undefined }
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const noEmit = isNil(props.modelValue)
    const state = ref(props.modelValue || false)
    const klass = computed(() => ({
      on: props.modelValue
    }))
    const style = computed(() => ({
      width: props.size,
      height: props.size
    }))
    const toggle = () => {
      if (!noEmit) {
        state.value = !state.value
        context.emit('update:modelValue', state.value)
      }
    }
    return {
      state,
      klass,
      style,
      toggle
    }
  }
})
</script>

<style lang="stylus">
@import "../../styles/commons.styl"
.sz-rounded-menu
  @extend .centered
  background: white;
  border: 1px dashed #d9d9d9;
  padding 7px
  border-radius 50%
  color #40a9ff
  &:hover
    color #4099cf
  &.on
    background-color #c0e9ff
    color #40a9ff
  svg
    width: 20px
    height: 20px
</style>
