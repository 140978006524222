
import { computed, defineComponent, reactive, UnwrapRef } from 'vue'

import Import from '@/components/network/GenieFileImporter.vue'
import { NETWORK_EXISTED_RECORD_TEXT } from '@/constants/components'
import { EMIT_EVENTS } from '@/constants/emits'
import { NetworkSchema } from '@/types'
import { NetworkDataDeletionCheckboxJSON } from '@/types/component'

export default defineComponent({
  components: { Import },
  props: {
    existedRecordText: Set,
    isNetworkUploadModalVisible: { type: Boolean, required: true },
    loadingStatus: { type: Boolean, default: false },
    deletionPromptText: { type: String, default: undefined },
    workspaceId: { type: String, default: undefined }
  },
  emits: [EMIT_EVENTS.NETWORK.LOAD],
  setup(props, { emit }) {
    const isVisible = computed(() => props.isNetworkUploadModalVisible)
    const recordDeletionCheckBox: UnwrapRef<NetworkDataDeletionCheckboxJSON> = reactive({
      isDeletingSurvey: true,
      isDeletingSurveyResponse: true,
      isDeletingCPT: true,
      isDeletingAllocation: true
    })

    /**
     * Handle load network
     **/
    const handleLoadNetwork = (network: NetworkSchema) => {
      emit(EMIT_EVENTS.NETWORK.LOAD, network)
    }

    /**
     * Handle click cancel
     */
    const handleCancel = () => {
      emit(EMIT_EVENTS.NETWORK.TOGGLE_IMPORT_NETWORK_MODAL)
    }

    /**
     * Handle confirm
     */
    const handleConfirm = () => {
      emit(EMIT_EVENTS.NETWORK.CONFIRM_DELETE_NETWORK_RECORDS, recordDeletionCheckBox)
    }

    return {
      NETWORK_EXISTED_RECORD_TEXT,
      EMIT_EVENTS,
      isVisible,
      recordDeletionCheckBox,
      handleCancel,
      handleConfirm,
      handleLoadNetwork
    }
  }
})
