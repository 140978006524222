
import { computed, defineComponent, reactive, UnwrapRef } from 'vue'

import { NETWORK_EXISTED_RECORD_TEXT } from '@/constants/components'
import { EMIT_EVENTS } from '@/constants/emits'
import { NetworkDataDeletionCheckboxJSON } from '@/types/component'

export default defineComponent({
  props: {
    isVariableChangesSaveModalVisible: { type: Boolean, required: true },
    existedRecordText: { type: Set, default: undefined },
    deletionPromptText: { type: String, default: undefined },
    loadingStatus: { type: Boolean, default: false }
  },
  emits: [
    EMIT_EVENTS.NETWORK.TOGGLE_VARIABLE_CHANGES_SAVE_MODAL,
    EMIT_EVENTS.NETWORK.SAVE_VARIABLE_CHANGES
  ],
  setup(props, { emit }) {
    const isVisible = computed(() => props.isVariableChangesSaveModalVisible)
    const recordDeletionCheckBox: UnwrapRef<Partial<NetworkDataDeletionCheckboxJSON>> = reactive({
      isDeletingSurvey: true,
      isDeletingSurveyResponse: true,
      isDeletingCPT: true
    })

    /**
     * Handle cancel
     */
    const handleCancel = () => {
      emit(EMIT_EVENTS.NETWORK.TOGGLE_VARIABLE_CHANGES_SAVE_MODAL)
    }

    /**
     * Handle cancel
     */
    const handleConfirm = () => {
      emit(EMIT_EVENTS.NETWORK.SAVE_VARIABLE_CHANGES, recordDeletionCheckBox)
    }

    return {
      NETWORK_EXISTED_RECORD_TEXT,
      isVisible,
      recordDeletionCheckBox,
      handleCancel,
      handleConfirm
    }
  }
})
