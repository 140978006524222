
import { computed, defineComponent } from 'vue'

import { EMIT_EVENTS } from '@/constants/emits'

export default defineComponent({
  props: {
    isDefaultNetworkSaveModalVisible: { type: Boolean, required: true }
  },
  emits: [
    EMIT_EVENTS.NETWORK.TOGGLE_DEFAULT_NETWORK_SAVE_MODAL,
    EMIT_EVENTS.NETWORK.SAVE_DEFAULT_NETWORK
  ],
  setup(props, { emit }) {
    const isVisible = computed(() => props.isDefaultNetworkSaveModalVisible)

    /**
     * Handle click cancel button
     */
    const handleClickCancel = () => {
      emit(EMIT_EVENTS.NETWORK.TOGGLE_DEFAULT_NETWORK_SAVE_MODAL)
    }

    /**
     * Handle click confirm button
     */
    const handleClickConfirm = () => {
      emit(EMIT_EVENTS.NETWORK.SAVE_DEFAULT_NETWORK)
    }

    return {
      isVisible,
      handleClickCancel,
      handleClickConfirm
    }
  }
})
