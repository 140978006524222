import { render } from "./NetworkView.vue?vue&type=template&id=0a092829"
import script from "./NetworkView.vue?vue&type=script&lang=ts"
export * from "./NetworkView.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0a092829"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0a092829', script)) {
    api.reload('0a092829', script)
  }
  
  module.hot.accept("./NetworkView.vue?vue&type=template&id=0a092829", () => {
    api.rerender('0a092829', render)
  })

}

script.__file = "src/views/NetworkView.vue"

export default script