import { render } from "./DefaultNetworkSaveModal.vue?vue&type=template&id=62f06522"
import script from "./DefaultNetworkSaveModal.vue?vue&type=script&lang=ts"
export * from "./DefaultNetworkSaveModal.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "62f06522"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('62f06522', script)) {
    api.reload('62f06522', script)
  }
  
  module.hot.accept("./DefaultNetworkSaveModal.vue?vue&type=template&id=62f06522", () => {
    api.rerender('62f06522', render)
  })

}

script.__file = "src/components/network/DefaultNetworkSaveModal.vue"

export default script