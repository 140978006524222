export enum Provider {
  // Network component
  NETWORK_SOURCE = 'networkSource',
  NETWORK = 'network',
  VARIABLES = 'variables',
  VARIABLE_MAP = 'variableMap',
  SELECTED_VARIABLE = 'selectedVariable',
  PARENTS = 'parents',
  CHILDREN = 'children',
  SORTED_COLLECTION_LIST = 'sortedCollectionList'
}
