<template>
  <div :id="id" ref="graphWrapper"></div>
</template>

<script lang="js">
import cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'
import fcose from 'cytoscape-fcose'
import { saveAs } from 'file-saver'
import ResizeObserver from 'resize-observer-polyfill'
import { defineComponent, inject, onBeforeUnmount, onMounted, ref, unref, watch } from 'vue'

import { VariableRelation } from '@/libs/bayes/enums/VariableRelation'
import { Provider } from '@/libs/constant'
import { CSS, VARIABLE_THEME } from '@/libs/theme'

cytoscape.use(dagre)
cytoscape.use(fcose)

const EMIT_EVENTS = {
  CLICK_NODE: 'clickNode'
}

export default defineComponent({
  props: {
    id: { type: String, required: true },
    elements: { type: Object, required: true }
  },
  emits: [EMIT_EVENTS.CLICK_NODE],
  setup(props, context) {
    const graphWrapper = ref(null)
    const resizeOb = new ResizeObserver(() => {
      console.log('resize called')
      if (cy) {
        cy.resize()
        cy.fit(null, 10)
      }
    })
    let cy
    const network = inject(Provider.NETWORK)
    const selectedVariable = inject(Provider.SELECTED_VARIABLE)
    const parents = inject(Provider.PARENTS)
    const children = inject(Provider.CHILDREN)
    const variableMap = inject(Provider.VARIABLE_MAP)

    const NODE_DEFAULT_STYLE = {
      'backgroundColor': '#a0a0a0'
    }

    const updateStyles = (nodes, relation) => {
      let styles
      if (!relation) {
        styles = NODE_DEFAULT_STYLE
      } else {
        styles = CSS(VARIABLE_THEME[relation], 0)
      }
      nodes.forEach((node) => {
        cy.$(`node#${node.id}`).style(styles)
      })
    }

    const updateConnectedStyles = (node) => {
      if (!node) { return }
      cy.edges().forEach((edge) => {
        cy.$(`edge#${edge.id()}`).unselect()
      })
      const edges = cy.$(`node#${node.id}`).connectedEdges()
      if (edges) {
        edges.forEach((edge) => {
          cy.$(`edge#${edge.id()}`).select()
        })
      }
    }

    /**
     * Move graph view to node id
     **/
    const centerView = (nodeId) => {
      if (nodeId) {
        const temp = cy.$(`node#${nodeId}`).neighborhood()
        cy.animate({
          center: {
            eles: temp
          }
        })
      } else {
        cy.center()
      }
    }

    /**
     * Pick the middle element in an array or the left element if we have 2 middle items
     **/
    const pickMiddleElementsInArray = (array) => {
      if (array.length > 0) {
        if (array.length % 2 !== 0) {
          const middleIndex = Math.floor(array.length / 2)
          return array[middleIndex]
        } else {
          const leftIndex = array.length / 2 - 1
          return array[leftIndex]
        }
      }
      return null
    }

    /**
     * Center selected node and it's parents and children, and style other nodes as a circle
     **/
    const applyFocusAndCenterStyle = ({ selected, parents, children }) => {
      if (Object.keys(selected).length > 0) {
        // toString is prevent default node id is an integer
        const selectedId = selected.id.toString()
        const parentsIds = parents.map(each => each.id.toString())
        const childrenIds = children.map(each => each.id.toString())

        const selectedNodePosition = cy.$(`node#${selectedId}`).position()

        const middleParent = pickMiddleElementsInArray(parentsIds)
        const middleChild = pickMiddleElementsInArray(childrenIds)
        // FIXME: currently, when first time load this component, the selectedId is not match elements in the graph
        if (cy.elements().map(each => each.data('id')).includes(selectedId)) {
          const constraints = {
            fixedNodeConstraint: undefined, // When you set alignmentConstraint, please do not set this value for specific nodes
            alignmentConstraint: undefined,
            relativePlacementConstraint: undefined
          }

          // Set relative
          if (selectedId) {
            constraints.relativePlacementConstraint = []
            if (middleParent) {
              constraints.relativePlacementConstraint.push({ top: middleParent, bottom: selectedId, gap: 100 })
            }
            if (middleChild) {
              constraints.relativePlacementConstraint.push({
                top: selectedId, bottom: middleChild, gap: 100
              })
            }
          }
          // This is used to prevent label overlap
          if (parents && Array.isArray(parents)) {
            for (let i = 0; i < parents.length - 1; i++) {
              let j = i + 1
              const left = parents[i]
              const right = parents[j]
              const leftNameLength = left.name.length
              const rightNameLength = right.name.length
              const gap = (leftNameLength + rightNameLength) / 2
              constraints.relativePlacementConstraint.push({
                left: left.id.toString(), right: right.id.toString(), gap
              })
            }
          }
          if (children && Array.isArray(children)) {
            for (let i = 0; i < children.length - 1; i++) {
              let j = i + 1
              const left = children[i]
              const right = children[j]
              const leftNameLength = left.name.length
              const rightNameLength = right.name.length
              const gap = (leftNameLength + rightNameLength) / 2
              constraints.relativePlacementConstraint.push({
                left: left.id.toString(), right: right.id.toString(), gap
              })
            }
          }

          // Set alignment
          if (parentsIds || childrenIds) {
            constraints.alignmentConstraint = { vertical: [], horizontal: [] }
            // Vertical align selected, parent and child if exists
            if (middleParent && middleChild) {
              constraints.alignmentConstraint.vertical.push([parentsIds[0], selectedId, childrenIds[0]])
            } else if (middleParent && !middleChild) {
              constraints.alignmentConstraint.vertical.push([middleParent, selectedId])
            } else if (middleChild && !middleParent) {
              constraints.alignmentConstraint.vertical.push([selectedId, middleChild])
            } else {
              delete constraints.alignmentConstraint.vertical
            }
            // Parallel align selected, parent and child if exists
            if (parentsIds.length > 0) {
              constraints.alignmentConstraint.horizontal.push([...parentsIds])
            }
            if (childrenIds.length > 0) {
              constraints.alignmentConstraint.horizontal.push([...childrenIds])
            }
          }

          const finalOptions = {
            name: 'fcose',
            quality: 'default',
            randomize: true,
            animate: true,
            animationDuration: 1000,
            animationEasing: undefined,
            fit: false,
            nestingFactor: 0.1,
            gravityRangeCompound: 1.5,
            gravityCompound: 1.0,
            step: 'all',
            nodeDimensionsIncludeLabels: true // This is useful to prevent node with long label crowd
          }

          finalOptions.fixedNodeConstraint = constraints.fixedNodeConstraint ? constraints.fixedNodeConstraint : undefined
          finalOptions.alignmentConstraint = constraints.alignmentConstraint ? constraints.alignmentConstraint : undefined
          finalOptions.relativePlacementConstraint = constraints.relativePlacementConstraint ? constraints.relativePlacementConstraint : undefined

          // Relevant nodes layout
          const relevantNodes = cy.nodes().filter(node => [selectedId, ...parentsIds, ...childrenIds].includes(node.data('id')))
          const relevantNodesLayout = relevantNodes.layout(finalOptions)
          // Remaining nodes layout
          const remainingNodes = cy.nodes().filter(node => ![selectedId, ...parentsIds, ...childrenIds].includes(node.data('id')))
          let layout
          if(remainingNodes.length > 6) {
            layout = cy.elements().layout({
              name: 'concentric',
              avoidOverlap: true,
              nodeDimensionsIncludeLabels: true,
              fit: false,
              animate: true,
              boundingBox: {
                x1: selectedNodePosition.x - 1,
                x2: selectedNodePosition.x + 1,
                y1: selectedNodePosition.y - 1,
                y2: selectedNodePosition.y + 1
              },
              levelWidth: function() {
                return 1
              },
              concentric: function(ele) {
                // Selected node in the very middle
                if (ele.same(cy.$(`node#${selectedId}`))) {
                  return Infinity
                } else if (ele.anySame(relevantNodes)) {
                  // other relevant nodes in the middle
                  return Infinity - 1
                } else {
                  return ele.degree()
                }
              }
            })
          } else {
            layout = remainingNodes.layout({
              avoidOverlap: !0,
              edgeSep: 50,
              name: 'dagre',
              nodeSep: 110,
              randomize: false,
              rankDir: 'TB',
              ranker: 'tight-tree',
              nodeDimensionsIncludeLabels: true,
              fit: false,
              animate: true,
            })
          }

          // After running the concentric layout to put all nodes in a circle with selected and relevant nodes in the center, run fcose layout to align relevant nodes
          layout.promiseOn('layoutstop').then(() => {
            relevantNodesLayout.promiseOn('layoutstop').then(() => {
              centerView(selectedId)
            })
            relevantNodesLayout.run()
          })
          layout.run()
        }
      }
    }

    onMounted(() => {
      cy = cytoscape({
        container: document.getElementById(props.id),
        elements: props.elements,
        style: [
          {
            selector: 'node',
            css: {
              'label': 'data(name)',
              'font-size': '8px',
              'background-color': '#a0a0a0', // '#0088aa',
              'background-opacity': 0.7,
              'padding': '3px',
              // 'width': '200px',
              // 'shape': 'circle',
              'text-halign': 'center',
              'text-valign': 'center'
              //'height': '8px'
            }
          },
          {
            selector: 'node:selected',
            style: {
              'border-width': '2px',
              'border-color': 'black',
              'border-style': 'solid'
            }
          },
          {
            selector: 'edge',
            css: {
              // 'color': 'data(color)',
              // 'control-point-step-size': 60,
              'curve-style': 'bezier',
              width: 1,
              'line-opacity': 0.5,
              // 'edge-text-rotation': 0,
              // 'font-size': 16,
              // 'label': 'data(label)',
              // 'line-color': 'data(color)',
              // 'line-style': 'data(style)',
              // 'loop-direction': -41,
              // 'loop-sweep': 181,
              // 'opacity': 1,
              // 'source-arrow-color': 'data(color)',
              // 'target-arrow-color': 'red',
              'target-arrow-shape': 'triangle'
              // 'text-background-color': '#ffffff',
              // 'text-background-opacity': 0,
              // 'text-background-padding': 5,
              // 'text-background-shape': 'roundrectangle',
              // 'text-margin-y': -16,
              // 'text-wrap': 'wrap',
              // 'width': 'mapData(strength, 0, 100, 1, 6)',
            }
          },
          {
            selector: 'edge:selected',
            css: {
              'color': 'red',
              'curve-style': 'bezier',
              width: 1,
              'line-opacity': 1,
              'target-arrow-shape': 'triangle'
            }
          }
        ],
        layout: {
          avoidOverlap: true,
          directed: true,
          name: 'breadthfirst',
          spacingFactor: 0.8
        },
        ready: function() {
          cy = this
          // Select current selected node and change node style
          cy.$(`node#${selectedVariable.value.id}`).select()
          updateStyles([selectedVariable.value], VariableRelation.SELECTED)
          updateConnectedStyles(selectedVariable.value)
          updateStyles(parents.value, VariableRelation.PARENT)
          updateStyles(children.value, VariableRelation.CHILD)
          centerView()

          // When click each node, change selected variable
          cy.on('click', 'node', function(e) {
            const node = e.target
            if (!Array.isArray(node)) {
              const nodeId = node.data('id').toString()
              const clickedVariable = unref(variableMap)[nodeId]
              context.emit(EMIT_EVENTS.CLICK_NODE, clickedVariable)
            }
          })
        }
      })
      if (graphWrapper.value) {
        console.log('start observe')
        resizeOb.observe(graphWrapper.value)
      }
    })

    onBeforeUnmount(() => {
      resizeOb.disconnect()
      cy.removeAllListeners() // Remove all cytoscope listener
      cy.destroy() // Free memory
    })

    /**
     * Change node style when selected variable change
     **/
    watch(
      selectedVariable,
      (newVar, oldVar) => {
        const currentSelectId = newVar.id
        const previousSelectId = oldVar.id

        updateStyles(network.value.variables)
        updateConnectedStyles(newVar)
        updateStyles([newVar], VariableRelation.SELECTED)
        updateStyles(parents.value, VariableRelation.PARENT)
        updateStyles(children.value, VariableRelation.CHILD)

        applyFocusAndCenterStyle({
          selected: selectedVariable.value,
          parents: parents.value,
          children: children.value
        })

        cy.$(`node#${previousSelectId}`).unselect()
        cy.$(`node#${currentSelectId}`).select()
      }
    )

    const exportImage = () => {
      // const b64key = 'base64,'
      // const content = cy.png()
      // const b64 = content.substring(content.indexOf(b64key) + b64key.length );
      // const imgBlob = base64ToBlob( , 'image/png' );

      saveAs(cy.png(), 'graph.png' )
    }

    return {
      exportImage,
      graphWrapper
    }
  }
})
</script>
